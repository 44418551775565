/**
 * Generates a new host string with the `-oh.` subdomain suffix.
 *
 * This function takes a host string, checks if it includes the `-oh.` suffix in the subdomain,
 * and returns a modified host with the `-oh.` suffix if it is not already present.
 *
 * @param {string} host - The original host string to be modified.
 * @returns {string} - The modified host string with the `-oh.` suffix added, or the original host if no modifications are needed.
 *
 */
export const generateOhioHost = (host: string): string => {
  // Split the host string on 'example'
  const parts = host?.split('risecannabis');

  // If 'example' is not found, return the original host
  if (!parts || parts.length < 2) return host;

  // Check if the subdomain already contains '-oh.'
  const subdomain = parts[0]?.includes('-oh.')
    ? parts[0] // If '-oh.' is present, keep the subdomain as is
    : (parts[0]?.replace(/\.$/, '-') ?? '') + 'oh.'; // Otherwise, add '-oh.' to the subdomain

  // Rebuild the host by concatenating the subdomain, 'example', and the rest of the original host
  const newHost = subdomain + 'risecannabis' + (parts[1]?.replace('/', '') ?? '');

  return newHost;
};

/**
 * Generates a URL specific to the Ohio environment by replacing the host and
 * cleaning up the provided slug. The function replaces placeholders in the slug
 * with an empty string and ensures that any double slashes in the resulting URL
 * are reduced to a single slash.
 *
 * @param {string} host - The current host string that will be transformed into an Ohio-specific host.
 * @param {string} slug - The URL path containing potential placeholders like `{{url}}` and `{{url-oh}}`.
 *                        These placeholders will be replaced with an empty string.
 *
 * @returns {string} The final Ohio-specific URL with placeholders removed and unnecessary slashes cleaned up.
 *
 */
export const generateOhioLink = (host: string, slug: string) => {
  if (!host && !slug) {
    return '';
  }

  const newHost = generateOhioHost(host);
  const cleanedSlug = slug?.replace('{{url}}', '').replace('{{url-oh}}', '').replace(/\/+/g, '/');
  return newHost + (cleanedSlug === '' ? '/' : cleanedSlug);
};
