import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { addToCartClicked } from 'helpers/analytics/cart-product-added';
import { SegementCartTriggerSource } from 'helpers/analytics/cart-track';
import { CartErrors, ICart } from 'helpers/types';
import Auth from 'helpers/utils/cognito';
import { RootState, store } from 'redux/store';
interface ICartState {
  cartTrigger: SegementCartTriggerSource;
  isLoading: boolean;
  error: CartErrors | null;
  carts?: { [key: string]: ICart };
  checkoutCompletedUUID: string;
}

const initialState: ICartState = {
  cartTrigger: null,
  isLoading: false,
  error: null,
  carts: {},
  checkoutCompletedUUID: null,
};

const BASE_API_URL = process.env.NEXT_PUBLIC_IHJ_PROXY_HOST + '/roots/carts_api/v1';

const janeCartClient = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

janeCartClient?.interceptors?.request?.use(
  async (req) => {
    const session = await Auth.getSession();
    const accessToken = session?.getAccessToken()?.getJwtToken();
    if (accessToken) {
      req.headers.Authorization = accessToken;
      return req;
    }
    return req;
  },
  (err) => {
    return Promise.reject(err);
  },
);

export const createCart = createAsyncThunk(
  'cart/createCart',
  async ({ store_id, fullfillmentType }: { store_id: number; fullfillmentType: string }, { rejectWithValue }) => {
    try {
      const user = store.getState().accountReducer.user;
      const response = await janeCartClient.post(`/carts`, { store_id, reservation_mode: fullfillmentType });
      if (response?.data?.errors) {
        throw Error(response?.data?.errors.error);
      }
      // update cart uuid and storeID in cognito if loggedIn user
      if (user) {
        const userCarts = user.attributes['custom:current_cart_id']
          ? JSON.parse(user.attributes['custom:current_cart_id'])
          : null;
        await Auth.updateUserAttributes([
          {
            Name: 'custom:current_cart_id',
            Value: JSON.stringify({
              ...userCarts,
              [response.data?.data?.store_id]: response.data?.data?.cart_uuid,
            }),
          },
        ]);
      }
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors.error);
    }
  },
);

export const addItemToCart = createAsyncThunk(
  'cart/addItemToCart',
  async (
    {
      cartUUID,
      pvid,
      quantity,
      addCartEvent,
    }: {
      cartUUID: string;
      pvid: string;
      quantity: number;
      addCartEvent;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await janeCartClient.post(`/carts/${cartUUID}/items`, { pvid, quantity });
      if (response?.data?.errors) {
        throw Error(response?.data?.errors.error);
      }

      // Send Segment event asynchronously
      Promise.resolve(addToCartClicked({ eventItem: addCartEvent, cartUUID: cartUUID })).catch((err) =>
        console.error('Segment addToCartClicked failed:', err),
      );

      return { cartReturn: response.data.data };
    } catch (error) {
      return rejectWithValue(error.response.data.errors.error);
    }
  },
);

export const updateCartItemQuantity = createAsyncThunk(
  'cart/updateCartItemQuantity',
  async ({ cartUUID, pvid, quantity }: { cartUUID: string; pvid: string; quantity: number }, { rejectWithValue }) => {
    try {
      const response = await janeCartClient.put(`/carts/${cartUUID}/items/${pvid}`, { quantity });
      if (response?.data?.errors) {
        throw Error(response?.data?.errors.error);
      }
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors.error);
    }
  },
);
export const removeCartItem = createAsyncThunk(
  'cart/removeCartItem',
  async ({ cartUUID, pvid }: { cartUUID: string; pvid: string }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${BASE_API_URL}/carts/${cartUUID}/items/${pvid}`);
      if (response?.data?.errors) {
        throw Error(response?.data?.errors.error);
      }
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.errors.error);
    }
  },
);

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    clearCart: (state, action) => {
      state.isLoading = false;
      const { store_id } = action.payload || {};
      state.carts[store_id] = {
        items: {},
        subTotal: null,
        discountPrice: null,
        cart_uuid: state.carts[store_id].cart_uuid,
      };
    },
    clearAllCarts: (state) => {
      state.isLoading = false;
      state.carts = {};
    },
    updateCartQuantity: (state, action) => {
      const { store_id, items } = action.payload || {};
      const cart = state.carts[store_id] as ICart;
      if (cart) {
        cart.items = items;
      }
    },
    addToCarts: (state, action: PayloadAction<{ store_id: string; cart: ICart }>) => {
      const { store_id, cart } = action.payload || {};
      state.carts[store_id] = cart;
    },
    removeFromCarts: (state, action: PayloadAction<{ store_id: string }>) => {
      const { store_id } = action.payload || {};
      delete state.carts[store_id];
    },

    setCheckoutCompletedUUID: (state, action) => {
      state.checkoutCompletedUUID = action.payload;
    },
    setCartTrigger: (state, action) => {
      state.cartTrigger = action.payload || {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCart.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createCart.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const { cart_uuid, store_id } = action.payload || {};
        state.carts[store_id] = { items: {}, cart_uuid, subTotal: null, discountPrice: null };
      })
      .addCase(createCart.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as CartErrors;
      })
      .addCase(addItemToCart.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addItemToCart.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const { cartReturn } = action.payload || {};
        const cart = state.carts[cartReturn.store_id] as ICart;
        if (cart) {
          cart.items = cartReturn.items;
        }
      })
      .addCase(addItemToCart.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as CartErrors;
      })
      .addCase(updateCartItemQuantity.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCartItemQuantity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const { store_id, items } = action.payload || {};
        const cart = state.carts[store_id] as ICart;
        cart.items = items;
      })
      .addCase(updateCartItemQuantity.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as CartErrors;
      })
      .addCase(removeCartItem.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(removeCartItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        const { store_id, items } = action.payload || {};
        const cart = state.carts[store_id] as ICart;
        if (cart) {
          cart.items = items;
        }
      })
      .addCase(removeCartItem.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as CartErrors;
      });
  },
});

export const selectCart = (state: RootState) => state.cart;
export const { setCartTrigger, addToCarts, removeFromCarts, clearAllCarts } = cartSlice.actions;

export default cartSlice.reducer;
