import tw from 'tailwind-styled-components';
import ChevronDownIcon from 'public/icons/figma/chevron-down.svg';
import CloseIcon from 'public/icons/figma/close-small.svg';

export const ACWidgetContainer = tw.div<{ $showChat: boolean }>`
    fixed
    z-[999]
    md:bottom-[2%]
    md:right-5
    md:w-auto
    md:h-auto
    w-full
    h-[calc(100%_-_48px)]
    bottom-0
    md:left-auto
    left-0
    top-12
    md:top-auto
    ${(p) => (p.$showChat ? 'block' : 'hidden')}

`;

export const ACFrameContainer = tw.div`
    md:w-[300px]
    md:h-[616px]
    md:static
    bg-white
    overflow-hidden
    md:rounded
    shadow-[0_24px_40px_0px_rgba(0,14,6,0.15)]
    absolute
    w-full
    h-full
    bottom-0
    left-0
`;

export const ACRootContainer = tw.div`
    w-full
    h-full
    rounded-[10px]
    bg-white
`;

export const ConnectCustomerInterfaceContainer = tw.div`
    h-full
    w-full
    m-0
    border-collapse
`;

export const ChatWrapper = tw.div`
    static
    md:absolute
    md:inset-0
    flex
    flex-col
    h-full
`;

export const HeaderContainer = tw.div`
    relative
    bg-leaf
    text-center
    text-white
    py-2
    rounted-t
    w-full
    h-[62px]
    px-3
    flex
    justify-center
`;
export const LogoHeaderWrapper = tw.div`
    absolute
    left-6
    top-1/2
    translate-y-[-50%]
    inline-flex
`;

export const LogoWrapper = tw.div`
    max-w-[28px]
    [&_img]:max-w-[28px]
`;

export const ContentWrapper = tw.div`
    flex
    flex-col
    md:max-h-[760px]
    h-screen
    overflow-auto
`;

export const StyledMinimize = tw(ChevronDownIcon)`
    lg:text-2xl
    text-xl
    h-auto
    w-auto
    text-white
    h-[24px]
    w-[24px]
`;

export const StyledExit = tw(CloseIcon)`
    lg:text-2xl
    sm:text-xl
    h-auto
    w-auto
    text-white
    h-[24px]
    w-[24px]
`;

export const ExitButtonContainer = tw.div`
    absolute
    align-middle
    right-3
    flex
    align-middle
    top-1/2
    translate-y-[-50%]
    [&_button]:inline-block
`;

export const ChatBubble = tw.button`
    fixed
    z-[999]
    bottom-[2%]
    right-5
    w-14
    lg:w-16
    h-14
    lg:h-16
    p-4
    lg:p-5
    border
    border-solid
    border-grey-480
    bg-white
    shadow-3
    rounded-[40px]
    flex
    items-center
    justify-center
    cursor-pointer
`;

export const ACWrapper = tw.div`
    bg-black
    bg-opacity-60
    md:bg-inherit
    bg-opacity-100
    fixed
    w-full
    h-full
    z-[9998]
`;
