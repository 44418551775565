export const getCookie = (c_name: string) => {
  let i, x, y;
  if (typeof document == 'undefined') {
    return null;
  }
  const ARRcookies = document ? document?.cookie?.split(';') : [];
  for (i = 0; i < ARRcookies.length; i++) {
    x = ARRcookies[i].substr(0, ARRcookies[i].indexOf('='));
    y = ARRcookies[i].substr(ARRcookies[i].indexOf('=') + 1);
    x = x.replace(/^\s+|\s+$/g, '');
    if (x == c_name) {
      return unescape(y);
    }
  }
};

export const getCookies = () => {
  const cookieString = document?.cookie;
  const cookieArray = cookieString?.split(';');
  const cookies = cookieArray?.reduce((acc, cookie) => {
    const [name, value] = cookie?.split('=');
    const trimmedName = name.trim();
    acc[trimmedName] = decodeURIComponent(value);
    return acc;
  }, {});
  return cookies;
};

export const setCookie = (c_name: string, value: string, exdays: number) => {
  const exdate = new Date();
  exdate.setDate(exdate.getDate() + exdays);
  const c_value = escape(value) + (exdays == null ? '' : ';path=/; expires=' + exdate.toUTCString());
  document.cookie = c_name + '=' + c_value;
};

export const setSessionCookie = (c_name: string, value: string) => {
  const c_value = escape(value) + ';path=/; expires=session';
  document.cookie = c_name + '=' + c_value;
};

export const parseCookieGroupPermissions = (cookie: string) => {
  if (!cookie) return null;
  const breakOnGroups = cookie?.split('&groups=') || [];
  const cookieGroupValues = breakOnGroups[1]?.split('&')[0];
  return cookieGroupValues;
};

export const parsedCookieValue = (cookies: string[], cookieName: string) => {
  return cookies.find((cookie) => cookie.includes(cookieName))
    ? cookies
        .find((cookie) => cookie.includes(cookieName))
        .split('=')[1]
        ?.split(';')[0]
    : null;
};

export const getCurrentStateSnackbarCookieValue = (snackBarCookies: string, geoState: string) => {
  const ccokieValues = decodeURIComponent(snackBarCookies).split('=closed;');
  return ccokieValues.includes(geoState);
};
