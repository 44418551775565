import tw from 'tailwind-styled-components';
export const LoaderContainer = tw.div`
    h-full
    w-full
    text-center
    align-middle
    flex
`;

export const NameTagContainer = tw.div`
    mb-1
`;

export const ContentContainer = tw.div<{ $direction: string }>`
    rounded
    p-3
    ${(p) =>
      p.$direction === 'outgoing'
        ? 'bg-kief after:content-["_"] after:absolute after:border-r-[none] after:border-l-[25px] after:border-l-kief after:border-y-[15px] after:border-y-transparent after:border-solid after:bottom-[-15px] after:right-0 after:rotate-180'
        : 'bg-grey-100 after:content-["_"] after:absolute  after:border-l-[none] after:border-r-[25px] after:border-r-grey-100 after:border-y-[15px] after:border-y-transparent after:border-solid after:bottom-[-15px] after:left-0 after:rotate-180'}
`;

export const InputContainer = tw.div`
    relative
    flex
    items-center
    min-h-[64px]
    border-y-[1px]
    border-y-solid

`;

export const Input = tw.div<{ $maxHeight: string }>`
    text-grey-400
    border-x-0
    border-b-0
    border-t-300
    border-t-[1px]
    border-t-solid
    absolute
    bottom-0
    w-full
    min-h-[64px]
    h-auto
    font-arial
    focus:ring-0
    focus:border-t-300
    p-5
    pr-9
    resize-none
    leading-5
    ${(p) => p.$maxHeight}
    !outline-none
    bg-white
    overflow-y-auto
    z-[2]
    max-h-[130.5px]
    scrollbar
`;

export const SendButton = tw.button`
    absolute
    top-5
    right-5
    z-[2]
    ml-auto
`;

export const FileContainer = tw.div`
    w-full
    flex
    gap-auto
`;

export const FileName = tw.button<{ $outgoing?: boolean; $deleteVisible: boolean }>`
    h-12
    self-stretch
    py-[14px]
    px-6
    text-leaf
    rounded-[32px]
    max-w-[248px]
    truncate
    ${(p) => (p.$outgoing ? 'bg-kief' : 'bg-grey-100')}
`;

export const placeHolderClasses = `
    absolute
    top-1/2
    translate-y-[-50%]
    left-5
    !text-grey-400
    text-base
    font-arial
    leading-[125%]
    z-[3]
    pointer-events-none
`;

export const MessageContainer = tw.div<{ $direction: string }>`
    ${(p) => (p.$direction === 'outgoing' ? 'col-[span_5_/_-1]' : 'col-span-5')}
    my-2
    h-auto
    relative
    break-words
`;

export const ChatBubbleContainer = tw.div`
    fixed
    z-[999999999]
    bottom-5
    right-5
`;

export const ChatBubbleButton = tw.div`
    text-white
    !bg-secondary-100
    w-[min(55px,_10.5vh)]
    h-[min(55px,_10.5vh)]
    rounded-[50%]
    flex
    items-center
    justify-center
    shadow-[0_0_8px_rgba(0,0,0,.3)]
    cursor-pointer
    transition-[background_0.5s_ease]
`;

export const InputField = tw.textarea<{ $maxHeight: string }>`
    w-auto
    !min-h-[56px]
    !h-auto
    grow
    font-arial
    outline-0
    border-0
    focus:outline-0
    focus:ring-transparent
    resize-none
    overflow-hidden
    ${(p) => p.$maxHeight}
`;

export const TextInput = tw.div`
    focus:outline-0
    focus:ring-transparent
`;

export const EndChatGateContainer = tw.div`
    md:w-[300px]
    md:h-[616px]
    w-full
    h-full
    z-[9999]
    absolute
    bottom-0
    left-0
    bg-white
    opacity-90
    table
`;

export const EndChatGateTextButtonWrapper = tw.div`
    table-cell
    align-middle
`;

export const ButtonContainer = tw.div`
    gap-x-4
    flex
    mx-auto
    justify-center
`;
