import { createSlice } from '@reduxjs/toolkit';

interface SearchState {
  data: {
    productsData: null | number;
    isProductsLoading: boolean;
    dispensariesData: null | number;
    isDispensariesLoading: boolean;
    articlesData: null | null;
    isArticlesLoading: boolean;
    sortValue: string;
    searchBar: { isOpen: boolean; data?: any };
  };
}

const initialState: SearchState = {
  data: {
    productsData: null,
    isProductsLoading: false,
    dispensariesData: null,
    isDispensariesLoading: false,
    articlesData: null,
    isArticlesLoading: false,
    sortValue: '',
    searchBar: { isOpen: false, data: null },
  },
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updateSearch: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    clearSearchState: (state) => {
      state.data = initialState.data;
    },
  },
});

export const { updateSearch, clearSearchState } = searchSlice.actions;

export default searchSlice.reducer;
