import { createSlice } from '@reduxjs/toolkit';

interface AgeGateState {
  isHidden: null | string;
}

const initialState: AgeGateState = {
  isHidden: null,
};

const ageGateSlice = createSlice({
  name: 'ageGate',
  initialState,
  reducers: {
    handleHiddenAgeGate: (state, action) => {
      state.isHidden = action?.payload;
    },
  },
});

export const { handleHiddenAgeGate } = ageGateSlice.actions;

export default ageGateSlice.reducer;
