import { SegmentEvents } from '../constants/forms';
import { SegementTrackEvent } from './segment-helper';
interface IChatSegmentEvent {
  state: string;
  dispensary: string;
  status: 'active' | 'inactive';
}

export const chatLaunchedTrack = (value: IChatSegmentEvent) => {
  try {
    const data: any = {
      dispensary_id: value?.dispensary || '',
      chat_viewed_at: new Date().toISOString(),
      state_name: value?.state || '',
      agent_status: value?.status || '',
    };
    SegementTrackEvent(SegmentEvents.CHAT_LAUNCHED, data, { sendGeoData: true });
  } catch (err) {
    throw Error(err);
  }
};
