import tw from 'tailwind-styled-components';
import { TTypographyVariant, colors, fontFamily, fontWeight } from './index';

const fontWeights = {
  bold: 'font-bold',
  semiBold: 'font-semibold',
  regular: 'font-normal',
  thin: 'font-medium',
};

const fontFamilys = {
  antartica: 'font-antarctican',
  arial: 'font-arial',
};

export const variant: Record<TTypographyVariant, (fontFamily: string, fontWeight: string) => string> = {
  h1: (fontFamily = 'font-antarctican', fontWeight = 'font-bold') =>
    `${fontFamily} ${fontWeight} text-[64px] uppercase not-italic leading-[56px] md:text-[116px] md:leading-[92px] xl:text-[160px] xl:leading-[128px]`,
  h2: (fontFamily = 'font-antarctican', fontWeight = 'font-semibold') =>
    `${fontFamily} ${fontWeight} text-5xl uppercase not-italic leading-[44px] tracking-[0.48px] md:text-[76px] md:leading-[68px] md:tracking-[0.76px] xl:text-[104px] xl:leading-[96px] xl:tracking-[1.04px]`,
  h3: (fontFamily = 'font-antarctican', fontWeight = 'font-medium') =>
    `${fontFamily} ${fontWeight} text-[32px] not-italic leading-8 tracking-[0.64px] md:text-[54px] md:leading-[51px] md:tracking-[0.648px] xl:text-[68px] xl:leading-[64px] xl:tracking-[0.816px]`,
  h4: (fontFamily = 'font-antarctican', fontWeight = 'font-medium') =>
    `${fontFamily} ${fontWeight} text-2xl uppercase not-italic leading-6 tracking-[0.48px] md:text-[32px] md:leading-[30px] md:tracking-[0.64px] xl:text-[42px] xl:leading-[40px] xl:tracking-[0.84px]`,
  h5: (fontFamily = 'font-antarctican', fontWeight = 'font-bold') =>
    `${fontFamily} ${fontWeight} text-xl uppercase not-italic leading-5 tracking-[0.2px] xl:text-2xl xl:leading-6 xl:tracking-[0.24px]`,
  'body-large': (fontFamily = 'font-arial', fontWeight = 'font-normal') =>
    `${fontFamily} ${fontWeight} text-base leading-5 xl:text-xl xl:leading-6 not-italic`,
  'body-large-bold': (fontFamily = 'font-arial', fontWeight = 'font-bold') =>
    `${fontFamily} ${fontWeight} text-base leading-5 xl:text-xl xl:leading-6 not-italic `,
  'body-small': (fontFamily = 'font-arial', fontWeight = 'font-normal') =>
    `${fontFamily} ${fontWeight} text-sm leading-4 tracking-14 xl:text-base xl:leading-5 xl:tracking-16 not-italic  md:text-[15px] md:leading-4.5 md:tracking-15`,
  'body-small-bold': (fontFamily = 'font-arial', fontWeight = 'font-bold') =>
    `${fontFamily} ${fontWeight} text-sm leading-4 tracking-14 xl:text-base xl:leading-5 xl:tracking-16 not-italic  md:text-[15px] md:leading-4.5 md:tracking-15`,
  'body-small-bold-underline': (fontFamily = 'font-arial', fontWeight = 'font-bold') =>
    `${fontFamily} ${fontWeight} text-sm leading-4 tracking-14 underline xl:text-base xl:leading-5 xl:tracking-16 not-italic md:text-[15px] md:leading-4.5 md:tracking-15`,
  small: (fontFamily = 'font-arial', fontWeight = 'font-normal') =>
    `${fontFamily} ${fontWeight} text-xs leading-5 tracking-[0.12px] xl:text-sm xl:leading-5 xl:tracking-14 not-italic md:text-[13px] md:leading-4.5 md:tracking-[0.13px]`,
};

const colorTexts = {
  light: 'text-light',
  dark: 'text-dark',
  'light-dark': 'text-light-dark',
  kief: 'text-kief',
  'kief-900': 'text-kief-900',
  'leaf-70': 'text-leaf-70',
  leaf: 'text-leaf',
  'leaf-dark': 'text-leaf-dark',
  'leaf-shadow': 'text-leaf-shadow',
  resin: 'text-resin',
  'grey-100': 'text-grey-100',
  'grey-200': 'text-grey-200',
  'grey-280': 'text-grey-280',
  'grey-250': 'text-grey-250',
  'grey-300': 'text-grey-300',
  'grey-400': 'text-grey-400',
  'grey-480': 'text-grey-480',
  'grey-500': 'text-grey-500',
  'grey-600': 'text-grey-600',
  'grey-700': 'text-grey-700',
  'grey-800': 'text-grey-800',
  'grey-900': 'text-grey-900',
  error: 'text-error',
  overlay: 'text-overlay',
  'hyperlink-100': 'text-hyperlink-100',
  'white-color-100': 'text-white-color-100',
  'white-color-200': 'text-white-color-200',
};
interface TypographyTagProps {
  $variant: TTypographyVariant;
  $color: colors;
  $fontFamily?: fontFamily;
  $fontWeight?: fontWeight;
}

export const TypographyTag = tw.p<TypographyTagProps>`
  ${(p) => colorTexts[p.$color]}
  ${(p) => variant[p.$variant](fontFamilys[p.$fontFamily], fontWeights[p.$fontWeight])}
`;
