import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface HostState {
  host: string;
  isCurrentHostOH: string;
}

const initialState: HostState = {
  host: '',
  isCurrentHostOH: null,
};

const hostSlice = createSlice({
  name: 'host',
  initialState,
  reducers: {
    updateHost: (state, action: PayloadAction<HostState>) => {
      state.host = action.payload.host;
      state.isCurrentHostOH = action?.payload?.isCurrentHostOH;
    },
  },
});

export const { updateHost } = hostSlice.actions;

export default hostSlice.reducer;
