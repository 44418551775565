import axios from 'axios';

export const shouldChatLoad = async () => {
  try {
    const response = await axios.get(process.env.NEXT_PUBLIC_AC_AVAIL_URL, {
      headers: {
        'Content-Type': 'application/json',
        authorizationToken: process.env.NEXT_PUBLIC_AC_AGENT_AVAIL_TOKEN,
      },
    });
    return response?.data?.agentsAvailable;
  } catch (e) {
    return false;
  }
};
