import cx from 'classnames';
import ChatModal from 'components/molecules/modals/chat-modal';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { getCurrentStateSnackbarCookieValue } from 'helpers/utils/cookies';
import * as screenSizes from 'helpers/utils/screensizes';
import { Cell as LayoutElement } from './cell';
import { TasticWrapper, highlightClassNames } from './component';
import { Errors } from './errors';
import { Grid } from './grid';
import {
  CellConfiguration,
  Cell as LayoutElementType,
  PageDataResponse,
  PagePreviewDataResponse,
  Tastic,
  TasticRegistry,
} from './types';

export const FrontasticRenderer = ({
  data,
  tastics = {},
  gridClassName,
  wrapperClassName,
  currentHighlight,
}: {
  data: PageDataResponse & PagePreviewDataResponse;
  tastics: TasticRegistry;
  gridClassName?: string;
  wrapperClassName?: string;
  currentHighlight?: string;
}) => {
  const [isBiggerThanMobile] = useMediaQuery(screenSizes.mobile);

  const deviceVisibility = (conf: CellConfiguration) => {
    return `${conf.mobile ? 'block' : 'hidden'} ${conf.tablet ? 'md:block' : 'md:hidden'} ${
      conf.desktop ? 'lg:block' : 'lg:hidden'
    }`;
  };

  const masterOrderList = {};
  const tasticPlacementOrder = [];
  let tasticPlacementIndex = 0;

  data?.page?.sections?.main?.layoutElements.map((l) => {
    l.tastics.forEach((tastic) => {
      const { tasticType, tasticId } = tastic;
      tasticPlacementOrder[tasticPlacementIndex] = { id: tasticId, type: tasticType };
      tasticPlacementIndex++;

      masterOrderList[tasticType] = masterOrderList[tasticType] || [];
      masterOrderList[tasticType].push(tasticId);
    });
  });

  const dataSourceId = data?.page?.sections?.head?.layoutElements[0]?.tastics?.filter(
    (tastic) => tastic.tasticType === 'global/page-seo',
  )[0]?.configuration['seoData']?.dataSourceId;
  const breadcrumbData = (data?.data?.dataSources[`${dataSourceId || ''}`]?.data as any)?.breadcrumbs;

  const snackBarCookies = data?.pageFolder?.cookies?.stickyBarCookies;
  const geoState = data?.pageFolder?.cookies?.stateID;

  if (data?.page?.sections?.kit) {
    return (
      <Grid wrapperClassName="w-full">
        {data?.page?.sections?.kit?.layoutElements.map((layoutElement: LayoutElementType) => (
          <LayoutElement
            size={layoutElement.configuration.size}
            className={highlightClassNames(currentHighlight === layoutElement.layoutElementId)}
            key={layoutElement.layoutElementId}
          >
            {layoutElement.tastics.map((t) => (
              <TasticWrapper
                tastics={tastics}
                key={t.tasticId}
                data={t}
                dataSources={data.data.dataSources}
                pageFolder={data.pageFolder}
                highlight={currentHighlight === t.tasticId}
                previewId={data?.previewId}
              />
            ))}
          </LayoutElement>
        ))}
      </Grid>
    );
  }

  return (
    <div className="flex min-h-screen flex-col items-stretch justify-start">
      {process && process.env.NODE_ENV !== 'production' && <Errors />}
      <Grid
        gridClassName={gridClassName}
        wrapperClassName={`${wrapperClassName} w-full ${highlightClassNames(currentHighlight === 'head')}`}
      >
        {data?.page?.sections?.head?.layoutElements.map((layoutElement: LayoutElementType) => (
          <LayoutElement
            size={layoutElement.configuration.size}
            className={cx(
              highlightClassNames(currentHighlight === layoutElement.layoutElementId),
              deviceVisibility(layoutElement.configuration),
            )}
            key={layoutElement.layoutElementId}
          >
            {layoutElement.tastics.map((t) => (
              <TasticWrapper
                tastics={tastics}
                key={t.tasticId}
                data={t}
                dataSources={data.data.dataSources}
                pageFolder={data.pageFolder}
                highlight={currentHighlight === t.tasticId}
                previewId={data?.previewId}
              />
            ))}
          </LayoutElement>
        ))}
      </Grid>
      <Grid
        id="mainContent"
        gridClassName={`${gridClassName} min-h-[90vh] mt-9 md:mt-10`}
        wrapperClassName={`${wrapperClassName} w-full grow mt-[60px] md:mt-[100px] lg:mt-[113px]${
          getCurrentStateSnackbarCookieValue(snackBarCookies, geoState) ? '' : ' visible-snack-bar-main-content'
        } ${highlightClassNames(currentHighlight === 'main')}`}
      >
        {data?.page?.sections?.main?.layoutElements.map((layoutElement: LayoutElementType) => {
          return (
            <LayoutElement
              size={isBiggerThanMobile ? layoutElement.configuration.size : 12}
              className={cx(
                highlightClassNames(currentHighlight === layoutElement.layoutElementId),
                deviceVisibility(layoutElement.configuration),
              )}
              key={layoutElement.layoutElementId}
            >
              {layoutElement.tastics.map((t: Tastic) => {
                return (
                  <TasticWrapper
                    tastics={tastics}
                    key={t.tasticId}
                    data={t}
                    dataSources={data.data.dataSources}
                    pageFolder={data.pageFolder}
                    highlight={currentHighlight === t.tasticId}
                    previewId={data?.previewId}
                    orderNumber={masterOrderList[t.tasticType].findIndex((item) => item === t.tasticId)}
                    tasticPlacementNumber={tasticPlacementOrder.findIndex((item) => item.id === t.tasticId) || 0}
                    breadcrumb={breadcrumbData || []}
                  />
                );
              })}
            </LayoutElement>
          );
        })}
      </Grid>
      <Grid
        gridClassName={gridClassName}
        wrapperClassName={`${wrapperClassName} w-full ${highlightClassNames(currentHighlight === 'footer')}`}
      >
        {data?.page?.sections?.footer?.layoutElements.map((layoutElement: LayoutElementType) => (
          <LayoutElement
            size={layoutElement.configuration.size}
            className={cx(
              highlightClassNames(currentHighlight === layoutElement.layoutElementId),
              deviceVisibility(layoutElement.configuration),
            )}
            key={layoutElement.layoutElementId}
          >
            {layoutElement.tastics.map((t: Tastic) => (
              <TasticWrapper
                tastics={tastics}
                key={t.tasticId}
                data={t}
                dataSources={data.data.dataSources}
                pageFolder={data.pageFolder}
                highlight={currentHighlight === t.tasticId}
                previewId={data?.previewId}
              />
            ))}
          </LayoutElement>
        ))}
      </Grid>
      <ChatModal />
    </div>
  );
};
