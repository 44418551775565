/**
 * Parses the input log levels string into a JSX-compatible format.
 *
 * If the input string is undefined, empty, or contains any invalid log levels,
 * it returns `undefined`. Otherwise, it splits the string into an array of valid log levels, each wrapped in single quotes.
 *
 * @param logLevelsData - A string containing log levels separated by commas.
 * @returns A formatted string representing an array of log levels: `['log','debug','info','warn','error']`, or `undefined` if input is invalid.
 */
export const parseLogLevels = (logLevelsData?: string): string | undefined => {
  const validLogLevels = ['log', 'debug', 'info', 'warn', 'error'];

  if (!logLevelsData || logLevelsData.trim() === '') {
    return undefined;
  }

  const levels = logLevelsData
    .split(',')
    .map((value) => value.trim())
    .filter((value) => validLogLevels.includes(value))
    .map((value) => `'${value}'`);

  return levels.length > 0 ? `[${levels.join(',')}]` : undefined;
};
