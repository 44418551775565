import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export enum SnackbarCtaType {
  CART_DRAWER = 'CART_DRAWER',
  NULL = null,
}
interface SnackbarState {
  isOpen: boolean;
  content?: string;
  ctaActionType?: SnackbarCtaType;
}

const initialState: SnackbarState = {
  isOpen: false,
  content: undefined,
  ctaActionType: null,
};

const snackbarNotificationSlice = createSlice({
  name: 'snackbarNotification',
  initialState,
  reducers: {
    openSnackbar: (state, action: PayloadAction<{ content: string; ctaActionType?: SnackbarCtaType }>) => {
      state.isOpen = true;
      state.content = action.payload.content;
      state.ctaActionType = action.payload.ctaActionType;
    },
    closeSnackbar: (state) => {
      state.isOpen = false;
      state.content = undefined;
      state.ctaActionType = null;
    },
  },
});

export const { openSnackbar, closeSnackbar } = snackbarNotificationSlice.actions;

export default snackbarNotificationSlice.reducer;
