import { useDispatch } from 'react-redux';
import { chatLaunchedTrack } from 'helpers/analytics/chat-launched-track';
import { AGE_GATE_COOKIE_NAME } from 'helpers/constants/cookies';
import { getCookie } from 'helpers/utils/cookies';
import { useAppSelector } from 'redux/hooks';
import { handleHiddenAgeGate } from 'redux/reducers/age-gate';
import { openChat } from 'redux/reducers/chat-slice';

export const useChatEventTracking = () => {
  const responseValidatedCookie = getCookie(AGE_GATE_COOKIE_NAME);
  const dispatch = useDispatch();
  const { status, state, dispensary } =
    useAppSelector((store) => {
      const status: 'active' | 'inactive' = store?.chat?.agentsAvailableFromRedux?.data ? 'active' : 'inactive';
      const currentDispensary = store?.dispensaryList?.currentDispensary || {};
      const state = currentDispensary?.state?.state?.stateName;
      const dispensary = currentDispensary?.dispensaries?.menusCollection?.items?.[0]?.menuSourceId;
      return { status, state, dispensary };
    }) || {};
  const openChatEvent = async () => {
    if (responseValidatedCookie) {
      dispatch(openChat());
      await chatLaunchedTrack({
        state,
        dispensary,
        status,
      });
    } else {
      dispatch(handleHiddenAgeGate('false'));
    }
  };
  return { openChatEvent };
};
