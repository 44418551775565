import { useEffect } from 'react';
import { useRouter } from 'next/router';
export interface StackAdaptScriptProps {
  store: any;
  pageProps: {
    data: {
      pageFolder: {
        name: string;
        cookies: {
          stateID: string;
          disID: string;
        };
      };
    };
  };
}

const StackAdaptScript = ({ pageProps, store }: StackAdaptScriptProps) => {
  const router = useRouter();
  const { cookies, name } = pageProps?.data?.pageFolder || {};
  const { stateID, disID } = cookies || {};
  const cookiePermissions = store?.getState()?.cookiesReducer?.cookieGroups;
  const isHomeSlug = router.asPath === '/';
  useEffect(() => {
    const foundScript: any = document.querySelector('script#stackadapt-script');
    const isHome = 'Home' === name && isHomeSlug;
    const isNj = 'new-jersey' === stateID;
    const isPaterson = 'paterson' === disID;
    const isBloomfield = 'bloomfield' === disID;
    const isOneTrustTargetSelected = cookiePermissions?.includes('C0004:1');
    if (isHome && isNj && isOneTrustTargetSelected && (isPaterson || isBloomfield)) {
      if (foundScript === null) {
        const noscript = document.createElement('noscript');
        noscript.id = 'stackadapt-noscript';
        const image = document.createElement('img');
        image.src = 'https://tags.srv.stackadapt.com/rt?sid=tR0EFQL9ijUfbLubiFBoRr';
        image.alt = 'stackadapt tags';
        image.width = 1;
        image.height = 1;
        noscript.appendChild(image);
        document.body.appendChild(noscript);
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.className = 'optanon-category-C0004';
        script.id = 'stackadapt-script';
        script.innerHTML = `
        !function(s,a,e,v,n,t,z){
          if(s.saq)return;
          n=s.saq=function(){
            n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)
          };
          if(!s._saq)s._saq=n;
          n.push=n;n.loaded=!0;
          n.version='1.0';
          n.queue=[];
          t=a.createElement(e);
          t.async=!0;
          t.src=v;
          z=a.getElementsByTagName(e)[0];
          z.parentNode.insertBefore(t,z)
        }
        (window,document,'script','https://tags.srv.stackadapt.com/events.js');
        saq('rt','tR0EFQL9ijUfbLubiFBoRr');
        `;
        document.body.appendChild(script);
      }
    } else {
      const foundNoScript = document.querySelector('noscript#stackadapt-noscript');
      foundScript?.remove();
      foundNoScript?.remove();
    }
  }, [name, disID, stateID, cookiePermissions, isHomeSlug]);

  return <></>;
};

export default StackAdaptScript;
