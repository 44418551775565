import * as React from 'react';
import { SWRConfig } from 'swr';
import { fetchApiHub } from '../../lib/fetch-api-hub';
import { FrontasticState, getFrontasticState } from './FrontasticState';

const initialState: FrontasticState = {};

export const FrontasticContext = React.createContext<FrontasticState>(initialState);

export const FrontasticProvider = ({ children }: any) => {
  const state: FrontasticState = getFrontasticState();
  return (
    <SWRConfig value={{ fetcher: fetchApiHub }}>
      <FrontasticContext.Provider value={state}>{children}</FrontasticContext.Provider>
    </SWRConfig>
  );
};

const checkContext = (context: FrontasticState) => {
  if (!context) {
    throw new Error('Expected to be wrapped in FrontasticProvider');
  }
};

export const useAccount = () => {
  const context = React.useContext(FrontasticContext);
  checkContext(context);
  return context;
};
