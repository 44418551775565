import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isTrackingEnabled: true,
};

const segmentTrackingSlice = createSlice({
  name: 'segmentTracking',
  initialState,
  reducers: {
    disableTracking: (state) => {
      state.isTrackingEnabled = false;
    },
    enableTracking: (state) => {
      state.isTrackingEnabled = true;
    },
  },
});

export const { disableTracking, enableTracking } = segmentTrackingSlice.actions;

export default segmentTrackingSlice.reducer;
