const inputTheme = {
  defaultProps: {
    variant: 'outlined',
    size: 'md',
    color: 'green',
    label: '',
    error: false,
    success: false,
    icon: undefined,
    labelProps: undefined,
    containerProps: undefined,
    shrink: false,
    className: '',
  },
  valid: {
    variants: ['outlined'],
    sizes: ['md'],
    colors: ['green'],
  },
  styles: {
    base: {
      container: {
        position: 'relative',
        width: 'sm:min-w-[200px] w-full',
        height: 'h-[48px] lg:h-[64px]',
      },
      input: {
        peer: 'peer',
        width: 'w-full',
        height: 'h-full',
        bg: 'bg-transparent',
        color: 'text-grey-400',
        fontFamily: 'font-arial',
        fontWeight: 'font-normal',
        lineHeight: '!leading-[0.14px]',
        outline: 'outline outline-0 focus:outline-0',
        disabled: 'disabled:bg-transparent disabled:border-1 disabled:!border-grey-300',
        transition: 'transition-all',
      },
      label: {
        display: 'flex',
        width: 'w-full',
        height: 'h-full',
        userSelect: 'select-none',
        pointerEvents: 'pointer-events-none',
        position: 'absolute',
        left: 'left-0',
        fontWeight: 'font-normal',
        fontFamily: 'font-arial',
        letterSpacing: 'tracking-[0.14px]',
        textOverflow: 'truncate',
        color: 'peer-placeholder-shown:text-grey-400',
        lineHeight: 'leading-tight peer-focus:!leading-tight',
        indent: 'peer-focus:[&_span.input-textfield-label]:!indent-0',
        floatLabel: 'peer-focus:[&_span.input-textfield-label]:bg-white',
        disabled: 'peer-disabled:text-grey-300 peer-disabled:peer-placeholder-shown:text-grey-300',
        transition: 'transition-all',
      },
      icon: {
        position: 'absolute',
        color: 'text-grey-400',
        top: 'top-[46%]',
        left: 'left-2.5',
      },
      asterisk: {
        color: 'text-resin',
      },
    },
    variants: {
      outlined: {
        base: {
          input: {
            borderWidth: 'placeholder-shown:border',
            borderColor: 'placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200',
            floated: {
              borderWidth: 'border focus:border',
              borderColor: 'border-t-transparent focus:border-t-transparent',
            },
          },
          icon: {
            top: 'top-2/4',
            right: 'right-3',
            transform: '-translate-y-2/4',
          },
          label: {
            position: '-top-1.5',
            pl: 'peer-focus:pl-0',
            fontSize: 'peer-placeholder-shown:text-sm',
            floated: {
              fontSize: 'text-[11px] peer-focus:text-[11px]',
            },
            before: {
              content: "before:content[' ']",
              display: 'before:block',
              boxSizing: 'before:box-border',
              width: 'before:w-2.5',
              height: 'before:h-1.5',
              mt: 'before:mt-[6.5px]',
              mr: 'before:mr-1',
              borderColor: 'peer-placeholder-shown:before:border-transparent',
              borderRadius: 'before:rounded-tl-md',
              floated: {
                bt: 'before:border-t peer-focus:before:!border-t',
                bl: 'before:border-l peer-focus:before:!border-l',
              },
              pointerEvents: 'before:pointer-events-none',
              transition: 'before:transition-all',
              disabled: 'peer-disabled:before:border-transparent',
            },
            after: {
              content: "after:content[' ']",
              display: 'after:block',
              flexGrow: 'after:flex-grow',
              boxSizing: 'after:box-border',
              width: 'after:w-2.5',
              height: 'after:h-1.5',
              mt: 'after:mt-[6.5px]',
              ml: 'after:ml-1',
              borderColor: 'peer-placeholder-shown:after:border-transparent',
              borderRadius: 'after:rounded-tr-md',
              floated: {
                bt: 'after:border-t peer-focus:after:border-t',
                br: 'after:border-r peer-focus:after:border-r',
              },
              pointerEvents: 'after:pointer-events-none',
              transition: 'after:transition-all',
              disabled: 'peer-disabled:after:border-transparent',
            },
          },
        },
        sizes: {
          md: {
            container: {
              height: 'h-10',
            },
            input: {
              fontSize: 'text-sm',
              px: 'px-3',
              py: 'py-2.5',
              borderRadius: 'rounded-[7px]',
            },
            label: {
              lineHeight: 'peer-placeholder-shown:leading-[4.25] lg:peer-placeholder-shown:leading-[5.25]',
            },
            icon: {
              width: 'w-5',
              height: 'h-5',
            },
          },
          lg: {},
        },
        colors: {
          input: {
            green: {
              borderColor: 'border-grey-480',
              borderColorFocused: 'focus:border-leaf',
            },
          },
          label: {
            green: {
              color: 'text-grey-480 peer-focus:text-leaf',
              before: 'before:border-grey-480 peer-focus:before:!border-leaf',
              after: 'after:border-grey-480 peer-focus:after:!border-leaf',
            },
          },
        },
        error: {
          input: {
            borderColor: 'border-error placeholder-shown:border-t-error placeholder-shown:border-error',
            borderColorFocused: 'focus:border-error',
            fontColor: 'text-grey-500',
          },
          label: {
            color: 'text-error peer-focus:text-error peer-placeholder-shown:text-error',
            before: 'before:border-error peer-focus:before:border-error',
            after: 'after:border-error peer-focus:after:border-error',
          },
        },
        success: {},
        shrink: {},
      },
      standard: {},
      static: {},
    },
  },
};

export default inputTheme;
