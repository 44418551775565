import { useRouter } from 'next/router';
import { addTrailingSlash } from 'helpers/utils/common';

const pageTypeMapping: object = {
  '/dispensaries/': 'dispensaryPage',
  '/dispensary-locations/': 'statePage',
  '/rise-rewards/': 'rewardsPage',
  '/faq/': 'faqPage',
  '/contact/': 'contactUsPage',
  '/about-us/': 'aboutUsPage',
  '/blog/recipes/': 'recipesIndexPage',
  '/blog/': 'blogIndexPage',
};

const getFlexiQueryType = (path: string): string | null => {
  const urlPath: string = addTrailingSlash(path);
  for (const entry in pageTypeMapping) {
    const regex = new RegExp(entry, 'g');
    if (urlPath.match(regex)) return pageTypeMapping[entry];
  }
  return null;
};

export const usePageType = (): string | null => {
  const { asPath } = useRouter();
  return getFlexiQueryType(asPath);
};
