import { Component, ErrorInfo, ReactNode } from 'react';
import Router from 'next/router';
import { fetchApiHub } from './fetch-api-hub';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(_error: Error): State {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // Report error to backend
    console.error({ error, errorInfo });
    fetchApiHub(
      '/frontendError',
      { method: 'POST' },
      {
        message: error.message,
        stack: error.stack,
      },
    ).then((r) => {
      console.error(r);
    });
  }

  render() {
    // Check if the error is thrown
    // if (this.state.hasError) {
    //   // You can render any custom fallback UI
    //   return (
    //     <div>
    //       <h2>Oops, there is an error!</h2>
    //       <p>Check the console for details.</p>
    //       <button type="button" onClick={() => this.setState({ hasError: false })}>
    //         Try again?
    //       </button>
    //     </div>
    //   );
    // }
    if (this.state.hasError) {
      Router.push('/error');
      // You can render any custom fallback UI
    }

    // Return children components in case of no error
    return this.props.children;
  }
}

export default ErrorBoundary;
