export const GEO_COOKIES = {
  STATE: 'gti_geoloc_stateslug',
  DISPENSARY: 'gti_geoloc_disslug',
  DISPENSARY_ID: 'gti_geoloc_disid',
};

export const COMMON_COOKIES = {
  STICKY_BAR: 'gti_sticky_bar',
};

export const DISP_TAGS = {
  COMING_SOON: 'Coming Soon',
  LOUNGE: 'Lounge',
  NON_LOYALTY: 'Non Loyalty',
};

export const MUNDY_STORE_ID = {
  DEV: '4596',
  PROD: '1342',
};
