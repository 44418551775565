export const ACConfig = {
  instanceId: process.env.NEXT_PUBLIC_AC_INSTANCE_ID,
  contactFlowId: process.env.NEXT_PUBLIC_AC_FLOWID,
  connectOptions: {
    accessKeyId: process.env.NEXT_PUBLIC_AC_ACCESS_KEY,
    secretAccessKey: process.env.NEXT_PUBLIC_AC_SECRET_KEY,
    region: 'us-east-1',
  },
  displayName: 'Customer',
};
