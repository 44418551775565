import { useMemo } from 'react';
import { MENU_MIS_CONFIG_ERROR_CODE, MENU_MIS_CONFIG_URL } from 'helpers/constants/error-handling';
import { TAdditionalContext } from 'helpers/types';
import { DISP_TAGS, MUNDY_STORE_ID } from 'helpers/types/cookies';
import { IDispensariesList } from 'helpers/types/forms';
import { TCurrentDispensary } from 'redux/reducers/dispensary-list';
import { store } from 'redux/store';
import { generateOhioHost } from './generate-oh-link';

const facetLabels = {
  category: 'root_types',
  brands: 'brand',
  feelings: 'feelings',
  specials: 'applicable_special_ids',
  'sub categories': 'root_types',
  activities: 'activities',
  lineage: 'category',
  'custom row': 'root_types',
};

export const ReplaceUrlWithHost = (url: string, host: string, isContent?: boolean) => {
  let newUrl = url;
  const isOH = store?.getState()?.hostReducer?.isCurrentHostOH;
  const { state } = store?.getState()?.dispensaryList?.currentDispensary;
  if (!isOH && state?.state?.stateName?.toLowerCase() === 'ohio' && newUrl?.includes('rise-rewards') && !isContent) {
    newUrl = newUrl?.replace('{{url}}', '{{url-oh}}');
  }
  if (!isOH && newUrl?.includes('ohio') && !isContent) {
    newUrl = newUrl?.replace('{{url}}', '{{url-oh}}');
  }
  if (!host) return newUrl?.replace(/{{url}}/g, '').replace(/{{url-oh}}/g, '');
  if (host?.includes('oh')) return newUrl?.replace(/{{url}}/g, host).replace(/{{url-oh}}/g, host);
  const ohHost = generateOhioHost(host);
  return newUrl?.replace(/{{url}}/g, host).replace(/{{url-oh}}/g, ohHost);
};

export const generateHashId = (str: string) => {
  if (str === null) {
    return '';
  }
  if (typeof str !== 'string') {
    str = String(str);
  }
  return `toc_${str.replace(/ /g, '_').toLowerCase()}`;
};

export const addTrailingSlash = (url: string) => {
  if (!url?.match(/\/$/)) {
    url += '/';
  }
  return url;
};

export const autoIdFromAdditionalContext = (additionalContext: TAdditionalContext, pageType?: string) => {
  if (!additionalContext || !additionalContext.componentType) return null;
  if (pageType === 'productListingPage' || pageType === 'productDetailPage') return null;

  const automationId = `${additionalContext?.skipPageType ? '' : pageType + '_'}${
    additionalContext?.componentType || ''
  }${additionalContext?.componentIndex?.toString() || ''}_${additionalContext?.tagRole || ''}${
    additionalContext?.roleIndex?.toString() || ''
  }`;

  return automationId;
};

export const MemoizedAutoIdFromAdditionalContext = (additionalContext: TAdditionalContext, pageType?: string) => {
  const memoizedResult = useMemo(
    () => autoIdFromAdditionalContext(additionalContext, pageType),
    [additionalContext, pageType],
  );

  return memoizedResult;
};

export const getMenuLink = ({
  menuType,
  fulfilmentType,
  dispensaries,
}: { menuType?: string; fulfilmentType?: string; dispensaries?: IDispensariesList } = {}) => {
  const host = store.getState().hostReducer.host;
  const currentDispensary = store?.getState()?.dispensaryList?.currentDispensary;
  const isOH = store.getState().hostReducer.isCurrentHostOH;
  const { storeId, dispensaryTags, menusCollection, slug } = dispensaries || currentDispensary.dispensaries;

  //handle local setuo
  let newStoreId = storeId;
  let mundyStoreId = MUNDY_STORE_ID.PROD;
  if (process.env.NEXT_PUBLIC_CUST_ENV !== 'production') {
    mundyStoreId = MUNDY_STORE_ID.DEV;
    newStoreId = storeId === MUNDY_STORE_ID.PROD ? MUNDY_STORE_ID.DEV : storeId;
  }

  const isComingSoon = dispensaryTags?.includes(DISP_TAGS.COMING_SOON);
  if (isComingSoon) {
    return host + slug;
  }

  const isLounge = dispensaryTags?.includes(DISP_TAGS.LOUNGE);
  if (isLounge) {
    // returning Mundy rec menu URL
    return `${host}${getMundyLoungeMenuLink(mundyStoreId)}`;
  }

  const menuDetails = menusCollection?.items;
  let filteredItems;

  if (menuType || fulfilmentType) {
    if (menuType && fulfilmentType) {
      filteredItems =
        menuDetails &&
        menuDetails?.find(
          (item) =>
            item?.menuType?.toLowerCase() === menuType?.toLowerCase() &&
            item?.menuFulfillmentType?.toLowerCase() === fulfilmentType?.toLowerCase(),
        );
    } else if (menuType) {
      filteredItems =
        menuDetails && menuDetails?.find((item) => item?.menuType?.toLowerCase() === menuType?.toLowerCase());
    } else if (fulfilmentType) {
      filteredItems =
        menuDetails &&
        menuDetails?.find((item) => item?.menuFulfillmentType?.toLowerCase() === fulfilmentType?.toLowerCase());
    }
  }

  if (!filteredItems) {
    if (storeId === 'null' || storeId === 'undefined') {
      filteredItems = menuDetails && menuDetails?.find((item) => item.isDefault === 'yes');
    } else {
      filteredItems = menuDetails && menuDetails?.find((item) => item.menuSourceId === newStoreId);
    }
  }

  const isExternal = filteredItems?.menuSource === 'external';
  if (isExternal) {
    return filteredItems?.menuSourceUrl;
  }

  const menuUrl = filteredItems?.linkedFrom?.pageMenuCollection?.items?.[0]?.slug;
  if (!isOH && menuUrl?.includes('ohio')) {
    return generateOhioHost(host) + menuUrl;
  }

  return menuUrl ? host + menuUrl : host + MENU_MIS_CONFIG_URL;
};

export const getMenuFiltersLink = (algoliaId?: string, algoliaValue?: string) => {
  const currentDispensary = store?.getState()?.dispensaryList?.currentDispensary;
  const menuLink = getMenuLink();
  if (menuLink.includes(MENU_MIS_CONFIG_ERROR_CODE)) {
    return menuLink;
  }

  const storeId = currentDispensary.dispensaries?.storeId;
  let urlObj = menuLink;
  if (algoliaId && algoliaValue && menuLink) {
    const facetLabel = facetLabels[algoliaId?.toLowerCase()] || '';
    urlObj =
      urlObj +
      '?' +
      `refinementList[${facetLabel}][]=${
        algoliaId.toLowerCase() === 'custom row'
          ? `${encodeURIComponent(algoliaValue)}-${storeId}`
          : encodeURIComponent(algoliaValue)
      }`;
    return urlObj?.toString() || '/';
  }

  return urlObj?.toString() || '/';
};

export const getPathMenuFiltersLink = (url: string, algoliaId?: string, algoliaValue?: string) => {
  const host = store.getState().hostReducer.host;
  const newUrl = ReplaceUrlWithHost(url, host);
  const currentDispensary = store?.getState()?.dispensaryList?.currentDispensary;
  const urlParams = new URLSearchParams(newUrl?.split('?')?.[1]);
  const fulfilmentType = urlParams?.get('fulfilment-type');
  const menuType = urlParams?.get('menu-type');
  const menuLink = getMenuLink({ menuType, fulfilmentType });
  if (menuLink.includes(MENU_MIS_CONFIG_ERROR_CODE)) {
    return menuLink;
  }

  const storeId = currentDispensary.dispensaries?.storeId;
  if (url?.includes('path=menu') && !url.includes(menuLink)) {
    const ctaHasRefinements = url.includes('refinementList');
    const filter = newUrl?.split('?');
    if (algoliaId && algoliaValue) {
      const href = window?.location?.href?.toString();
      const paramsString = href?.split('?')?.[1];
      const urlParams = new URLSearchParams(paramsString);
      const ctaParams = new URLSearchParams(filter[1]);
      const paramsArray = Array?.from(urlParams?.entries());
      const ctaParamsArray = Array?.from(ctaParams?.entries());
      const kindParams = paramsArray?.filter(([key]) => key !== 'refinementList[root_types][]');
      const facetLabel = facetLabels[algoliaId.toLowerCase()] || '';
      let allParams = [
        ...kindParams,
        [
          `refinementList[${facetLabel}][]`,
          algoliaId.toLowerCase() === 'custom row' ? `${algoliaValue}-${storeId}` : algoliaValue,
        ],
      ];
      if (ctaHasRefinements) {
        allParams = [...allParams, ...ctaParamsArray];
      }
      const newParams = allParams?.map(([key, value]) => `${key}=${encodeURIComponent(value)}`);
      const newParamsString = newParams
        ?.filter((item, index) => {
          return newParams.indexOf(item) === index;
        })
        ?.join('&');

      return `${menuLink}?${newParamsString}`;
    } else {
      return `${menuLink}?${filter[1]}`;
    }
  }

  return newUrl;
};

export const getLinkTarget = (url: string, openInNewTab = false) => {
  const host = store.getState().hostReducer.host;
  const isOH = store.getState().hostReducer.isCurrentHostOH;
  return !url?.includes(host) || openInNewTab
    ? '_blank'
    : !isOH && url?.includes('ohio') && url?.includes('oh')
    ? '_blank'
    : '_self';
};

export const getMundyLoungeMenuLink = (mundyStoreId: string) => {
  return `/dispensaries/illinois/mundelein/${mundyStoreId}/recreational-menu/`;
};

export const isComplianceLocation = (currentDispensary: TCurrentDispensary) => {
  const isComplaint =
    currentDispensary?.state?.state?.stateName?.toLowerCase() === ('connecticut' || 'ohio') ||
    currentDispensary?.dispensaries?.slug?.includes('lake-in-the-hills');

  return isComplaint;
};

export const isLITH = () => {
  const state = store?.getState()?.dispensaryList?.currentDispensary;
  const isLITH = state?.dispensaries?.slug?.includes('lake-in-the-hills');
  return isLITH;
};
