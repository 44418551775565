import { ButtonType, Color, Size } from './common-buttons/button';

const baseButtonCss = (size: Size) => `
    text-[14px]
    font-arial
    text-sm
    leading-4
    md:text-[15px]
    md:leading-[18px]
    xl:tracking-[0.16px]
    xl:text-base
    xl:leading-5
    font-bold
    rounded-[32px]
    ${
      size === 'sm'
        ? 'px-5 py-3 md:px-[22px] xl:px-6 xl:py-3.5'
        : '  py-4 px-[26px] md:py-[18px] md:px-7 xl:px-[38px] xl:py-[22px]'
    }
`;

const primaryButtonCss = (color: Color, disabled?: boolean) => `
    focus:md:ring
    focus:md:ring-offset-2
    ${
      color === 'green'
        ? 'bg-leaf text-white hover:text-kief hover:bg-leaf-dark focus:md:ring-leaf-dark'
        : 'bg-white text-leaf hover:bg-grey-100 hover:text-leaf-dark focus:md:ring-kief'
    } ${
  disabled
    ? '!bg-grey-300 !text-grey-400 !opacity-1 cursor-default hover:!bg-grey-300 hover:!text-grey-400 hover:!opacity-1'
    : ''
}
`;

const secondaryButtonCss = (color: Color, disabled?: boolean) => `
    ${
      color === 'green'
        ? 'border border-leaf-70 text-leaf hover:text-leaf-dark hover:border-leaf-dark focus:md:ring-leaf-dark'
        : 'border border-white text-white hover:text-grey-100 hover:border-grey-100 focus:md:ring-kief'
    } ${disabled ? '!border-grey-300 !text-grey-300 cursor-default hover:!border-grey-300 hover:!text-grey-300' : ''}
`;

const tertiaryButtonCss = (color: Color, disabled?: boolean) => `
    ${
      color === 'green'
        ? 'text-leaf hover:text-leaf-dark hover:underline'
        : 'text-white hover:text-kief hover:underline'
    } ${disabled ? '!text-grey-300 cursor-default hover:!no-underline	 hover:!text-grey-300' : ''}
`;

export const buttonCss = (buttonType: ButtonType, size: Size, color: Color, disabled?: boolean) => {
  switch (buttonType) {
    case 'primary':
      return baseButtonCss(size).concat(primaryButtonCss(color, disabled));
    case 'secondary':
      return baseButtonCss(size).concat(secondaryButtonCss(color, disabled));
    case 'tertiary':
      return baseButtonCss(size).concat(tertiaryButtonCss(color, disabled));
  }
};
