import { createSlice } from '@reduxjs/toolkit';
import { IContentfulImage, IRichTextData } from 'helpers/types';

interface chatState {
  isChatMinimized: boolean;
  isChatOpen: boolean;
  agentName: string;
  modalData: {
    logo: IContentfulImage | null;
    title: string;
    agentUnavailabilityMessage: IRichTextData | null;
    chatFormError: IRichTextData | null;
    chatFormSuccess: IRichTextData | null;
    chatFormApiError: IRichTextData | null;
  };
  isChatContentEditable: boolean;
  currentAgentMessageId: string;
  agentsAvailableFromRedux: {
    loading: boolean;
    data: null;
  };
}

const initialState: chatState = {
  isChatMinimized: false,
  isChatOpen: false,
  isChatContentEditable: true,
  agentName: null,
  currentAgentMessageId: null,
  modalData: {
    logo: null,
    title: '',
    agentUnavailabilityMessage: null,
    chatFormError: null,
    chatFormSuccess: null,
    chatFormApiError: null,
  },
  agentsAvailableFromRedux: {
    loading: false,
    data: null,
  },
};

export const chatSlice = createSlice({
  name: 'accordionToggle',
  initialState,
  reducers: {
    openChat: (state) => {
      state.isChatOpen = true;
    },
    closeChat: (state) => {
      state.isChatOpen = false;
      state.agentName = null;
    },
    setIsChatOpen: (state, action) => {
      state.isChatOpen = action.payload;
    },
    setChatModalData: (state, action) => {
      state.modalData = action.payload;
    },
    setChatContentEditable: (state, action) => {
      state.isChatContentEditable = action.payload;
    },
    setCurrentAgentMessageId: (state, action) => {
      state.currentAgentMessageId = action.payload;
    },
    setAgentsAvailable: (state, action) => {
      state.agentsAvailableFromRedux.data = action.payload;
    },
    setAgentsAvailableLoadingStatus: (state, action) => {
      state.agentsAvailableFromRedux.loading = action.payload;
    },
    setAgentName: (state, action) => {
      state.agentName = action.payload;
    },
    setChatMinimized: (state, action) => {
      state.isChatMinimized = action.payload;
    },
  },
});

export const {
  openChat,
  closeChat,
  setIsChatOpen,
  setChatModalData,
  setChatContentEditable,
  setCurrentAgentMessageId,
  setAgentsAvailable,
  setAgentsAvailableLoadingStatus,
  setAgentName,
  setChatMinimized,
} = chatSlice.actions;

export default chatSlice.reducer;
