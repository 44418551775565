import Router from 'next/router';
import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  CookieStorage,
  ICognitoUserAttributeData,
} from 'amazon-cognito-identity-js';
import cookieCutter from 'cookie-cutter';
import { jwtDecode } from 'jwt-decode';
import { setUser } from 'redux/reducers/account';
import { clearAllCarts } from 'redux/reducers/product-cart';
import { store } from 'redux/store';

type TEmailVerificationType = 'link' | 'code';

type User = CognitoUser & { attributes: any };
class AuthClass {
  private userPool: CognitoUserPool;
  private currentUser: CognitoUser;

  constructor() {
    const poolData = {
      UserPoolId: process.env.NEXT_PUBLIC_AMS_POOLID,
      ClientId: process.env.NEXT_PUBLIC_AMS_CLIENTID,
      AuthFlow: 'CUSTOM_AUTH',
      Storage: new CookieStorage(),
    };
    this.userPool = new CognitoUserPool(poolData);
  }

  getCurrentUser() {
    return this.userPool.getCurrentUser();
  }

  getCognitoUser(username: string) {
    const userData = {
      Username: username,
      Pool: this.userPool,
      Storage: new CookieStorage(), //{ domain: window?.location?.hostname }
    };
    const cognitoUser = new CognitoUser(userData);
    return cognitoUser;
  }

  getUpdatedUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getAttributes()
        .then((userProfile: any) => {
          const user = { ...this.currentUser, attributes: userProfile } as User;
          resolve(user);
        })
        .catch(() => {
          reject(null);
        });
    });
  }

  getSession(): Promise<null | CognitoUserSession> {
    if (!this.currentUser) {
      this.currentUser = this.userPool.getCurrentUser();
    }
    return new Promise<CognitoUserSession>((resolve, reject) => {
      this.currentUser.getSession((err, session: CognitoUserSession) => {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    }).catch(() => {
      this.signOut();
      return null;
    });
  }

  signUp(username: string, password: string, attributeList: CognitoUserAttribute[]) {
    return new Promise((resolve, reject) => {
      this.userPool.signUp(username, password, attributeList, [], (err, res) => {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
    }).catch((err) => {
      throw err;
    });
  }

  signIn(username: string) {
    return new Promise((resolve, reject) => {
      const authenticationData = {
        Username: username,
      };
      const authenticationDetails = new AuthenticationDetails(authenticationData);

      this.currentUser = this.getCognitoUser(username);
      this.currentUser.setAuthenticationFlowType('CUSTOM_AUTH');
      this.currentUser.challengeName = 'CUSTOM_CHALLENGE';
      this.currentUser.initiateAuth(authenticationDetails, {
        onSuccess: () => {
          this.getAttributes().then((userProfile: any) => {
            resolve(userProfile);
          });
        },
        onFailure: (err: any) => {
          reject(err);
        },
        customChallenge: () => {
          resolve('');
        },
      });
    }).catch((err) => {
      throw err;
    });
  }

  sendCustomChallengeAnswer(answerChallenge: string) {
    return new Promise((resolve, reject) => {
      this.currentUser.sendCustomChallengeAnswer(answerChallenge, {
        onSuccess: () => {
          this.getAttributes().then((userProfile: any) => {
            const user = { ...this.currentUser, attributes: userProfile };
            resolve(user);
          });
        },
        onFailure: (err: any) => {
          reject(err);
        },
        customChallenge: () => {
          resolve({ challengeName: 'CUSTOM_CHALLENGE' });
        },
      });
    });
  }

  signOut() {
    if (this.currentUser) {
      this.currentUser.signOut();
      store.dispatch(setUser(null));
      Router.push('/');
      if (cookieCutter.get('isUser') === 'false') {
        cookieCutter.set('isUser', 'false', { expires: new Date(0) });
      }
      store.dispatch(clearAllCarts());
      this.currentUser = null;
    } else {
      store.dispatch(setUser(null));
    }
  }

  getAttributes() {
    return new Promise((resolve, reject) => {
      this.currentUser.getUserAttributes((err: any, attributes: any) => {
        if (err) {
          reject(err);
        } else {
          const userProfile = attributes.reduce((acc: any, { Name, Value }: any) => ({ ...acc, [Name]: Value }), {});
          resolve(userProfile);
        }
      });
    }).catch((err) => {
      throw err;
    });
  }

  updateUserAttributes(attribute: ICognitoUserAttributeData[]) {
    return new Promise((resolve, reject) => {
      this.currentUser.updateAttributes(attribute, (err: any) => {
        if (err) {
          reject(err);
        } else {
          const user = store.getState().accountReducer.user;
          const changedAttributes = attribute.map((attr) => {
            return {
              [attr.Name]: attr.Value,
            };
          });
          const userCopy = { ...user, attributes: { ...user.attributes, ...changedAttributes[0] } };
          store.dispatch(setUser(userCopy));
          resolve('success');
        }
      });
    }).catch((err) => {
      throw err;
    });
  }

  verifyEmail(type: TEmailVerificationType) {
    return new Promise((resolve, reject) => {
      const clientMetaData =
        type === 'link'
          ? {
              isLink: 'yes',
            }
          : {};

      this.currentUser.getAttributeVerificationCode(
        'email',
        {
          onSuccess: (result) => {
            resolve(result);
          },
          onFailure: (err) => {
            reject(err);
          },
          inputVerificationCode: null,
        },
        clientMetaData,
      );
    }).catch((err) => {
      throw err;
    });
  }

  verifyEmailSubmit(code: string) {
    return new Promise((resolve, reject) => {
      this.currentUser.verifyAttribute('email', code, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    }).catch((err) => {
      throw err;
    });
  }

  async checkSessionForServer(req): Promise<boolean> {
    const lastAuthUser =
      req.cookies[`CognitoIdentityServiceProvider.${process.env.NEXT_PUBLIC_AMS_CLIENTID}.LastAuthUser`];

    const cookiesKey = Object.keys(req.cookies);
    const getCognitoToken = (type: 'accessToken' | 'refreshToken' | 'idToken') => {
      switch (type) {
        case 'accessToken':
          return req.cookies[cookiesKey.find((x: string) => x.includes('accessToken'))];
        case 'refreshToken':
          return req.cookies[cookiesKey.find((x: string) => x.includes('refreshToken'))];
        case 'idToken':
          return req.cookies[cookiesKey.find((x: string) => x.includes('idToken'))];
      }
    };

    const cognitoAccessToken = getCognitoToken('accessToken');
    const cognitoRefreshToken = getCognitoToken('refreshToken');
    const cognitoIdToken = getCognitoToken('idToken');

    if (!lastAuthUser || !cognitoAccessToken || !cognitoRefreshToken) {
      return null;
    }

    // check if session valid
    const decoded: any = jwtDecode(cognitoAccessToken);
    const utcSeconds: number = decoded.auth_time;
    const authTime = new Date(+utcSeconds * 1000); // The 0 there is the key, which sets the date to the epoch
    const currentTime = new Date();
    const authTimeDIff = currentTime.getTime() - authTime.getTime();
    const cognitoUser = this.getCognitoUser(lastAuthUser);
    this.currentUser = cognitoUser;
    const refreshTokenTime = 2629746000;
    if (authTimeDIff < refreshTokenTime) {
      // session valid, check if access token expired, then refresh the token
      const accessTokenLastModified = new Date(+decoded.exp * 1000);
      const currentTime = new Date();
      const accessTokenTimeDiff = accessTokenLastModified.getTime() - currentTime.getTime();
      cognitoUser.setSignInUserSession(
        new CognitoUserSession({
          AccessToken: new CognitoAccessToken({ AccessToken: cognitoAccessToken }),
          RefreshToken: new CognitoRefreshToken({ RefreshToken: cognitoRefreshToken }),
          IdToken: new CognitoIdToken({ IdToken: cognitoIdToken }),
        }),
      );
      if (accessTokenTimeDiff < 0) {
        // access token expired, get new token using refresh token
        const newSession: any = await this.refreshSession(lastAuthUser, cognitoRefreshToken);
        cognitoUser.setSignInUserSession(newSession);
      }
      return true;
    }
    return false;
  }

  async refreshSession(username: string, refreshToken: string) {
    return new Promise((res, rej) => {
      this.getCognitoUser(username).refreshSession(
        new CognitoRefreshToken({ RefreshToken: refreshToken }),
        (err, result) => {
          if (err) {
            rej(err);
          } else {
            res(result);
          }
        },
      );
    });
  }
}

const Auth = new AuthClass();

export default Auth;
