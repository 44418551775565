import {
  ButtonContainer,
  EndChatGateContainer,
  EndChatGateTextButtonWrapper,
} from 'components/atoms/amazon-connect-chat/styles';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
interface EndChatGateProps {
  setEndChat(status: boolean): void;
  setEndChatGate(status: boolean): void;
}
export const EndChatGate = ({ setEndChat, setEndChatGate }: EndChatGateProps) => {
  return (
    <EndChatGateContainer>
      <EndChatGateTextButtonWrapper>
        <Typography variant="h5" className="mb-6 text-center">
          Are you sure you want to end the chat?
        </Typography>
        <ButtonContainer>
          <CommonButton onClick={() => setEndChatGate(false)} size="lg" button-type="secondary" color="green">
            Cancel
          </CommonButton>
          <CommonButton
            onClick={() => {
              setEndChat(true);
              setEndChatGate(false);
            }}
            size="lg"
            button-type="primary"
            color="green"
          >
            Exit
          </CommonButton>
        </ButtonContainer>
      </EndChatGateTextButtonWrapper>
    </EndChatGateContainer>
  );
};
