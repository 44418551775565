import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { isDevelopment } from 'helpers/utils/environment';
import {
  accountReducer,
  ageGateReducer,
  cartSliceReducer,
  chatReducer,
  cookieSliceReducer,
  dispensaryListDrawerSliceReducer,
  dispensaryListReducer,
  hostSliceReducer,
  loungePopupReducer,
  productReducer,
  searchReducer,
  segmentTrackingReducer,
  snackbarNotificationSlice,
} from './reducers';

const middleware = [];
// commet to trigger the build
console.log('isDevelopment', isDevelopment());
// if (isDevelopment()) middleware.push(createLogger());
const rootReducer = combineReducers({
  hostReducer: hostSliceReducer,
  dispensaryListDrawerReducer: dispensaryListDrawerSliceReducer,
  accountReducer: accountReducer,
  loungePopup: loungePopupReducer,
  chat: chatReducer,
  dispensaryList: dispensaryListReducer,
  segmentTracking: segmentTrackingReducer,
  cart: cartSliceReducer,
  snackbarNotification: snackbarNotificationSlice,
  product: productReducer,
  cookiesReducer: cookieSliceReducer,
  search: searchReducer,
  ageGate: ageGateReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cart', 'accountReducer'],
  timeout: 500, // https://gtigrows.atlassian.net/wiki/spaces/HC/pages/800325651/Next.js+Performance+with+Redux-Persist
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk, ...middleware],
  devTools: isDevelopment(),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
