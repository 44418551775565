import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loungePopup: {
    reservation: false,
    eventCalender: false,
    smokeasy: false,
  },
};

export const loungePopupSlice = createSlice({
  name: 'loungePopup',
  initialState,
  reducers: {
    setOpenLoungeModal: (state, action) => {
      state.loungePopup = action.payload;
    },
  },
});

export const { setOpenLoungeModal } = loungePopupSlice.actions;

export default loungePopupSlice.reducer;
